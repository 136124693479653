#sports {
  background: #fff;

  .heading {
    font-size: 5.5rem;
  }

  .services-table {
    width: 100%;
    td {
      min-width: 100px;
      border: 1px solid var(--primary-color);
      padding: 0.2rem 1rem;
      font-family: "Bebas Neue", cursive;
      font-size: 2.6rem;
      &:first-child {
        text-align: center;
        color: var(--primary-color);
        font-size: 3.8rem;
      }
    }
  }

  @media screen and (max-width: 820px) {
    .heading {
      font-size: 2.3rem;
    }
    .services-table {
      td {
        font-size: 1.6rem;
        line-height: 1.2;
        &:first-child {
          font-size: 2.2rem;
        }
      }
    }
  }
}

footer {
  background: #000;
  color: #fff;
  .col {
    h1 {
      font-weight: 500;
      margin-bottom: 0.5em;
    }
    h3 {
      color: var(--primary-color);
      margin-bottom: 1.5rem;
      font-family: "Bebas Neue", cursive;
      font-size: 1.4rem;
    }
    ul {
      li {
        font-weight: 300;
        font-size: 0.9em;
        padding: 0.2em 0;
        cursor: pointer;
        &:hover {
          color: #fff;
        }
      }
    }
    .icon_row {
      display: flex;
      align-items: center;
      svg {
        transition: 0.2s;
        cursor: pointer;
        &:hover {
          path.color {
            fill: var(--primary-color);
            transition: 0.2s;
          }
        }
      }
    }
    a {
      cursor: pointer;
      &:hover {
        color: var(--primary-color);
      }
    }
  }
  .bottom {
    // background: #0e0e0e;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #fff;
  }
}

@media screen and (max-width: 575px) {
  footer {
    text-align: center;
    img {
      margin: auto;
    }
    .col {
      .icon_row {
        justify-content: center;
      }
    }
    .bottom {
      // flex-direction: column;
      small {
        // margin: 0.5em 0;
      }
    }
  }
}

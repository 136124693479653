.carousel {
  position: relative;
  overflow: hidden;
  .slide {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    // border: 2px solid red;
    img,
    video {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      object-position: bottom center;
    }
    .audio_control_btn {
      position: absolute;
      bottom: 50px;
      right: 50px;
      z-index: 2;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      cursor: pointer;
      transition: 0.2s;
      align-items: center;
      svg {
        fill: #fff;
      }
      &:hover {
        background: rgba(0, 0, 0, 0.6);
        transition: 0.2s;
      }
    }
  }
  .carousel_nav {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    .nav {
      position: absolute;
      transform: translateY(-50%);
      mix-blend-mode: difference;
      svg {
        height: 40px;
        cursor: pointer;
      }
      &.left {
        left: 0;
        transform: translateY(-50%) rotate(180deg);
      }
      &.right {
        right: 0;
      }
    }
  }
  .dots {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    z-index: 2;
    .dot {
      height: 10px;
      width: 10px;
      background: #fff;
      border-radius: 50%;
      margin: 0 5px;
      &.active{
        background: var(--primary-color);
      }
    }
  }
  &.contain {
    .slide {
      display: flex;
      align-items: center;
      img,
      video {
        width: 100%;
        height: auto;
      }
    }
  }
  @media screen and (max-width: 575px) {
    max-height: 60vh;
    .slide {
      .audio_control_btn {
        bottom: 5px;
        right: 5px;
        height: 30px;
        width: 30px;
        svg {
          transform: scale(0.7);
        }
      }
    }
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #da1b22;
  --background-color: #ebf6f3;
  --secondary-color: #ebf0f6;
  --primary-color-hover: #c30208;
}

.primary-bg {
  background: var(--primary-color);
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* font-family: "Bebas Neue", cursive; */
  font-family: "Poppins", sans-serif;
  background: #000;
  overflow-x: hidden;

  padding-top: 74px;
}

@media screen and (max-width: 820px) {
  body {
    padding-top: 54px;
  }
}

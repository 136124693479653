#newsBlogs {
  background: #f5f5f5;
  .card {
    border: 1px solid var(--primary-color);
    cursor: pointer;
    .img {
      height: 350px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      }

    }
    .body {
      padding: 1rem;
      background: #fff;
      small {
        color: var(--primary-color);
      }
      h3 {
        margin: 0.5rem 0;
        color: var(--primary-color);
        color: #000;
        font-family: "Bebas Neue", cursive;
        font-size: 2rem;
        line-height: 1.1;
      }
      p {
        color: #555555;
      }
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  .blog_detail {
    h1 {
      color: var(--primary-color);
      color: #222;
      font-family: "Bebas Neue", cursive;
      font-weight: 500;
    }
    .date {
      color: var(--primary-color);
    }
    p{
      margin: 1rem 0;
    }
    a{
      // color: #0782C1;
      &:hover{
        color: #0782C1;
        text-decoration: underline;
      }
    }
  }

  .seperator {
    background: var(--primary-color);
  }

  .latest_card {
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 2rem;
    background: #daddde;
    padding: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    h3 {
      color: var(--primary-color);
      font-family: "Bebas Neue", cursive;
      font-weight: 500;
      line-height: 1.3;
    }
    small {
      color: var(--primary-color);
    }
  }

  @media screen and (max-width: 820px) {
    .latest_card {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }
}

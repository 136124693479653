#talentSection {
  background: #fff;
  .talent_banner {
    position: relative;
    .container {
      position: absolute;
      bottom: 0;
    }
    img {
      width: 100%;
      height: auto;
      max-height: 100vh;
      object-fit: cover;
    }
    h1 {
      color: var(--primary-color);
      line-height: 1;
      font-family: "Bebas Neue", cursive;
      font-size: 7rem;
      margin: 0;
      overflow: hidden;
      span {
        display: block;
        transform: translateY(150%);
      }
    }
  }
  .talent_grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0;
    align-items: stretch;
  }
  @media screen and (max-width: 820px) {
    .talent_banner {
      h1 {
        font-size: 2rem;
      }
    }
    .talent_grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  // max-height: 100%;
  // position: sticky;
  // top: 0;
  display: flex;
  flex-direction: column;
  z-index: 999;
  // backdrop-filter: saturate(180%) blur(20px);
  // background: rgba(255, 255, 255, 0.8);
  background: #fff;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    // border: 1px solid red;
    img {
      height: 50px;
    }
  }
  .mob_logo {
    display: none;
  }
  .nav_links {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      margin: 0 25px;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        color: var(--primary-color);
      }
    }
    button {
      background: var(--primary-color);
      color: #fff;
      padding: 0.5rem 1rem;
      border-radius: 100vmax;
      &:hover {
        background: var(--primary-color-hover);
      }
    }
    .close {
      display: none;
    }
    .dropdown_wrapper {
      position: relative;
      z-index: 2;
      .dropdown {
        position: absolute;
        background: #fff;
        background: none;
        top: 100%;
        display: none;
        // display: flex;
        flex-direction: column;
        transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
        box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
        padding: 1.2rem 0 0;
        .link {
          padding: 0.8rem 2rem;
          white-space: nowrap;
          cursor: pointer;
          text-decoration: none;
          color: var(--copy-color);
          margin: 0;
          background: #000;
          text-transform: uppercase;
          border-bottom: 1px solid #222;
          transition: 0.2s;
          &:hover {
            background: var(--primary-color);
            color: #f1f3f4;
          }
        }
      }
      &:hover {
        .dropdown {
          display: flex;
          animation: slidein 0.2s ease-out;
          @keyframes slidein {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
          }
        }
      }
    }
  }
  .menu_open_btn {
    display: none;
  }
}

@media screen and (max-width: 820px) {
  nav {
    .logo {
      img {
        height: 30px;
      }
    }
    .mob_logo {
      display: block;
      img {
        height: 30px;
      }
    }
    .nav_links {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      background: rgba(255, 255, 255, 0.9);
      background: linear-gradient(
        rgba(255, 255, 255, 0.95) 70%,
        rgba(255, 255, 255, 0.7)
      );
      flex-direction: column;
      justify-content: flex-start;
      padding-top: 50px;
      align-items: stretch;
      a {
        text-align: right;
        margin: 30px 0 0;
        padding: 0 50px;
        font-size: 1.5em;
      }
      .close {
        display: block;
        position: absolute;
        top: 30px;
        right: 50px;
        z-index: 2;
      }
      .logo {
        display: none;
      }
      .dropdown_wrapper {
        .dropdown {
          right: 0;
          .link {
            font-size: 1.5rem;
          }
        }
      }
    }
    .menu_open_btn {
      display: block;
    }
  }
}

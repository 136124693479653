#contact {
  background: #fff;
  form {
    input,
    textarea,
    select {
      padding: 0.5rem 1rem;
      border: 1px solid var(--primary-color);
      &::placeholder {
        color: #999;
      }
    }
    select {
      // color: #999;
      &:invalid {
        color: #999;
      }
    }
  }
  ul {
    li {
      border-bottom: 1px solid var(--primary-color);
      display: grid;
      grid-template-columns: 30% 1fr;
      padding: 1rem 0;
      .col {
        display: flex;
        justify-content: space-between;
      }
      h3 {
        color: var(--primary-color);
        font-family: "Bebas Neue", cursive;
        font-size: 1.4rem;
      }
      a {
        color: var(--primary-color);
      }
    }
  }
  @media screen and (max-width: 820px) {
    ul {
      li {
        grid-template-columns: 1fr;
      }
    }
  }
}

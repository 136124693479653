.talent_card {
  position: relative;
  isolation: isolate;
  // overflow: hidden;
  cursor: pointer;
  // will-change: transform;
  .talent_detail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100vw;
    // z-index: -1;
    // opacity: 0;
    backdrop-filter: blur(10px);
    max-height: 100vh;
    overflow-y: auto;
    padding: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .container {
      background: var(--primary-color);
      width: auto;
      max-width: 1100px;
      // height: 100%;
      display: flex;
      position: relative;
      & > .col {
        margin-left: 3rem;
        flex: 2;
        // border: 2px solid blue;
        overflow-y: auto;
      }
    }
    .player {
      width: 100%;
      max-height: 80vh;
      max-width: 40%;
      object-fit: cover;
      object-position: top center;
      opacity: 0;
      flex: 1;
    }
    .back {
      display: block;
      margin: auto;
      position: absolute;
      top: 5px;
      right: 0;
      width: 50px;
      height: auto;
      fill: #fff;
      height: 40px;
    }
    h3 {
      // margin-top: 2rem;
      color: #fff;
      font-size: 5rem;
      font-family: "Bebas Neue", cursive;
      line-height: 1;
    }
    p {
      font-size: 1.6rem;
      color: #fff;
    }
    ul{
      list-style: disc;
    }
    .social_icons{
      svg{
        cursor: pointer;
      }
    }
  }
  &.full-screen {
    isolation: auto;
    overflow: unset;
    .talent_detail {
      position: fixed;
      opacity: 1;
      z-index: 9999;
      .player {
        opacity: 1;
      }
    }
  }
  & > img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: relative;
    // transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .l1 {
    position: absolute;
    bottom: 0;
    left: 0;
    height: calc(2rem + 1.2rem + 4rem);
    width: 100%;
    background-color: rgba(218, 27, 34, 0.9);
    // mix-blend-mode: multiply;
    transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .l2 {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: #fff;
    transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition-delay: 0.05s;
    h3 {
      font-family: "Bebas Neue", cursive;
      font-size: 2rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
  &:hover {
    // & > img {
    //   transform: scale(1.05);
    // }
    .l1 {
      height: 100%;
    }
    .l2 {
      transform: translateY(-20%);
    }
  }
  .curtain {
    // position: absolute;
    // top: 0;
    // left: 0;
    // height: 100%;
    // width: 100%;
    // background: #000;
    // transform-origin: bottom;
    // will-change: transform;
    display: none;
  }
  @media screen and (max-width: 575px) {
    .talent_detail {
      padding: 0;
      .container {
        width: auto;
        max-width: 100%;
        height: auto;
        display: block;
        & > .col {
          margin-left: 0;
        }
      }
      .player {
        max-height: 50vh;
      }
      h3 {
        margin-top: 2rem;
        font-size: 2.4rem;
      }
      p {
        font-size: 1rem;
      }
    }
  }
}

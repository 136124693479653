#talentManagement {
  background: #fff;
  .heading {
    font-size: 5.5rem;
  }
  ul {
    list-style: disc;
    padding-left: 1rem;
    li {
      font-family: "Bebas Neue", cursive;
      font-size: 1.6rem;
    }
  }
  @media screen and (max-width: 820px) {
    .heading {
      font-size: 2.3rem;
    }
  }
}

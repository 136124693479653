#team {
  // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 5.76%, #000000 63.64%);
  background: linear-gradient(180deg, #000 12%, rgb(255, 255, 255) 63.64%);
  // background: linear-gradient(180deg, #000, rgb(255, 255, 255));
  h1 {
    color: var(--primary-color);
    text-align: center;
    font-family: "Bebas Neue", cursive;
    font-size: 7rem;
    overflow: hidden;
    span {
      display: block;
      transform: translateY(150%);
    }
  }
  p {
    text-align: center;
  }

  @media screen and (max-width: 820px) {
    background: linear-gradient(180deg, #000 5%, rgb(255, 255, 255) 25%);
    h1 {
      font-size: 3rem;
      line-height: 1;
    }
  }
}

#client {
  background: #fff;
  .heading {
    font-size: 5.5rem;
  }
  ul {
    list-style: disc;
    padding-left: 1rem;
    li {
      // font-family: "Bebas Neue", cursive;
      // font-size: 1.4rem;
      margin: 1rem 0;
    }
  }

  @media screen and (max-width: 820px) {
    .heading {
      font-size: 2.3rem;
    }
    ul {
      li {
        // font-size: 1.4rem;
      }
    }
  }
}

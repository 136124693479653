.hero_banner {
  position: relative;
  z-index: 2;
  .slide {
    position: relative;
    .l1 {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      // border: 2px solid red;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .name {
        font-family: "Bebas Neue", cursive;
        font-size: 9rem;
        margin: 0;
        line-height: 0.8;
        overflow: hidden;
        span {
          display: block;
          transform: translateY(150%);
        }
      }
      p {
        text-transform: uppercase;
        font-size: 2.4rem;
      }
    }
  }
  .scroll_down {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }
}

#about {
  background: linear-gradient(rgba(255, 255, 255, 0), #000 35%, #000);
  color: #fff;
  position: relative;
  // background: #000;
  & > .container {
    border-top: 1px solid rgba(255, 255, 255, 0.8);
    border-bottom: 1px solid rgba(255, 255, 255, 0.8);
  }
  .heading {
    white-space: nowrap;
  }
}

#talent {
  .talent_grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0;
    align-items: stretch;
    .col {
      position: relative;
      img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      .l1 {
        position: absolute;
        bottom: 0;
        left: 0;
        height: calc(2rem + 1.2rem + 4rem);
        width: 100%;
        background: #da1b22;
        mix-blend-mode: multiply;
      }
      .l2 {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        color: #fff;
        h3 {
          font-family: "Bebas Neue", cursive;
          font-size: 2rem;
        }
        p {
          font-size: 1.2rem;
        }
      }
    }
  }
}

#boxing {
  background: #fff;
  overflow: hidden;
  .flex {
    // border: 1px solid red;
    .left {
      flex: 1;
      font-size: 3rem;
      margin-right: -10%;
      line-height: 1.1;
    }
    .center {
      flex: 4;
      pointer-events: none;
      img{
        transform: scale(1.45);
      }
    }
    .right {
      flex: 2;
      margin-left: -20%;
      margin-top: 15%;
      font-size: 1.2rem;
      p {
        max-width: 80%;
      }
      button {
        background: #000;
      }
    }
  }
}

#services {
  background: var(--primary-color);
  .grid {
    .col {
      border-right: 1px solid #000000;
      position: relative;
      .bg {
        position: absolute;
        top: 0;
        left: 0;
        background: #000;
        height: 100%;
        width: 100%;
        transform: scaleY(0);
        transform-origin: top;
        transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
      }
      h3 {
        color: #fff;
        font-size: 3rem;
        line-height: 1;
        font-family: "Bebas Neue", cursive;
        position: relative;
      }
      p {
        // opacity: 0;
        color: #fff;
        position: relative;
        transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
        transition-delay: 0.07s;
      }
      button {
        // opacity: 0;
        position: relative;
        transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
        transition-delay: 0.14s;
      }
      &:hover {
        .bg {
          transform: scaleY(1);
        }
        p {
          opacity: 1;
        }
        button {
          opacity: 1;
        }
      }
    }
  }
}

#brands {
  background: #fff;
  .capitalize {
    text-transform: capitalize;
  }
  .logos_grid {
    img {
      filter: grayscale(1);
      opacity: 0.5;
      transition: 0.5s;
      max-width: 200px;
      &:hover {
        opacity: 1;
      }
    }
  }
}
#updates {
  background: #eee;
  overflow: hidden;
  .carousel {
    display: flex;
    overflow: visible;
    overflow-x: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
  .card {
    margin-right: 2rem;
    background: #fff;
    flex: 0 0 25vw;
    overflow: hidden;
    img {
      width: 100%;
      transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    .body {
      background: #fff;
      margin-top: -2rem;
      width: 90%;
      position: relative;
      transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
      h3 {
        // color: var(--primary-color);
        color: #000;
        // font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.2;
        font-family: "Bebas Neue", cursive;
      }
      .date{
        color: var(--primary-color);
      }
    }
    &:first-child {
      margin-left: 10rem;
    }
    &:hover {
      box-shadow: 5px 5px 10px 2px rgba(255, 255, 255, 0.2);
      img {
        transform: scale(1.08);
      }
      .body {
        transform: translateY(-5px);
      }
    }
  }
  .nav {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .progress {
      height: 2px;
      width: 30%;
      position: relative;
      background: #000;
      .bar {
        bottom: 0;
        left: 0;
        height: 200%;
        width: 100%;
        transform-origin: left;
        transform: scaleX(0);
        background: var(--primary-color);
        position: absolute;
      }
    }
    button {
      svg {
        height: 3.5rem;
      }
      &:hover {
        rect {
          fill: var(--primary-color);
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .hero_banner {
    // margin-top: 56px;
    .slide {
      .l1 {
        .name {
          font-size: 2.2rem;
        }
        p {
          font-size: 0.9rem;
        }
      }
    }
    .scroll_down {
      bottom: 14%;
      svg {
        height: 2rem;
        width: auto;
      }
    }
  }

  #talent {
    .talent_grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  #boxing {
    .flex {
      .left {
        font-size: 1.8rem;
        margin-right: 0;
        line-height: 1.2;
        margin-top: 2rem;
      }
      .right {
        margin-left: 0;
        margin-top: 2rem;
        font-size: 1rem;
        p {
          max-width: 100%;
        }
      }
    }
  }

  #services {
    .grid {
      .col {
        border-bottom: 1px solid #999;
        .bg {
          transform: scaleY(1);
        }
        p {
          opacity: 1;
        }
        button {
          opacity: 1;
        }
      }
    }
  }

  #brands {
    .logos_grid {
      img {
        max-width: 110px;
      }
    }
  }

  #updates {
    // .carousel {
    //   overflow-x: auto;
    // }
    .card {
      margin-right: 2rem;
      background: #fff;
      flex: 0 0 65vw;
      img {
        width: 100%;
        height: auto;
      }
      .body {
        background: #fff;
        margin-top: -2rem;
        width: 90%;
        position: relative;
        h3 {
          color: var(--primary-color);
          // font-weight: 600;
          font-size: 1.4rem;
          font-family: "Bebas Neue", cursive;
        }
      }
      &:first-child {
        margin-left: 0;
      }
    }
    .nav {
      display: none;
    }
  }
}

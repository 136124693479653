.primary-color {
  color: var(--primary-color);
}

.heading {
  color: var(--primary-color);
  font-family: "Bebas Neue", cursive;
  line-height: 1;
  margin: 0;
  font-size: 7rem;
  position: relative;
  will-change: transform;
  &.anim {
    overflow: hidden;
    span {
      display: block;
      transform: translateY(150%);
    }
  }
  &.text-white {
    color: #fff;
  }
  .bg {
    position: absolute;
    top: 0;
    left: 30%;
    color: #555555;
    opacity: 0.08;
    transform: scale(1.6);
    will-change: transform;
    &.left {
      left: auto;
      right: 35%;
    }
    &.black-bg {
      color: #d9d9d9;
      opacity: 0.05;
    }
    &.red-bg {
      color: #000;
      opacity: 0.05;
    }
  }
  sup {
    font-size: 0.3em;
    top: -2.5em;
  }
  &.h2 {
    font-size: 1.8rem;
  }
}

.sub_heading {
  font-family: "Philosopher", sans-serif;
  font-size: 2rem;
}

.seperator {
  height: 1px;
  background: #c4c4c4;
}

.breadcrumb {
  display: flex;
  align-items: center;
  .item {
    color: #a2a2a2;
    padding: 0 0.2em;
    font-size: 0.85em;
    cursor: pointer;
    &:hover {
      color: #666;
      text-decoration: underline;
    }
    &.current {
      color: var(--primary-color);
      cursor: default;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.p_btn {
  background: var(--primary-color);
  padding: 0.8em 1.5em;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.4px;
  transition: 0.2s;
  border-radius: 100vmax;
  &:hover {
    background: var(--primary-color-hover);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  }
  &:active {
    transform: scale(0.95);
  }
}

.outline-btn {
  padding: 1.3rem 1rem 1rem;
  border: 1px solid #000;
  font-family: "Bebas Neue", cursive;
  font-size: 2.6rem;
  line-height: 1;
  position: relative;
  z-index: 1;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: left;
    transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  &:hover {
    color: var(--primary-color);
    &::after {
      transform: scaleX(1);
    }
  }
  &:active {
    transform: scale(0.98);
  }
  @media screen and (max-width: 820px) {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 575px) {
  .heading {
    font-size: 2.3rem;
  }
  .sub_heading {
    font-size: 1.2rem;
  }
  .breadcrumb {
    .item {
      padding: 0 0.1em;
      font-size: 0.7em;
      line-height: 1;
      white-space: nowrap;
      &:last-child {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
